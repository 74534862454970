<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.order_change_item_receipted") }}
      <v-spacer></v-spacer>
      <span class="fs-12">
        {{ $t("labels.order_prevent_pickup_on") }}
      </span>
      <span class="error--text fs-12">
        {{ displayCountdown }}
      </span>
    </v-card-title>

    <v-card-text>
      <div>
        <v-simple-table class="table-padding-4 mb-3">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.create_time") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.shop") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.tracking") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.customer_order_id") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.receiver") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.phone_number") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.cod") }}
                  <span
                    v-if="order.create_method == 1 || order.create_method == 2"
                    class="font-italic font-weight-light"
                    >({{ $t("labels.can_update") }})</span
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  {{ formatDateTime(order.create_time) }}
                </td>
                <td class="text-center">
                  {{ order.customer_e_market_shop_name }}
                </td>
                <td class="text-center">{{ order.tracking_id }}</td>
                <td class="text-center">{{ order.customer_order_id }}</td>
                <td class="text-center">{{ order.receiver_name }}</td>
                <td class="text-center">{{ order.receiver_phone_number }}</td>
                <td class="text-center" style="max-width: 160px">
                  <input-number
                    v-if="order.create_method == 1 || order.create_method == 2"
                    v-model.number="newCod"
                    class="c-input-xs my-1"
                    type="number"
                    min="0"
                    :label="$t('labels.cod')"
                    dense
                    outlined
                    hide-details
                    single-line
                  />
                  <span v-else>{{ formatNumber(order.cash_on_delivery) }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="mb-3">
          <v-alert
            color="error"
            text
            dense
            border="left"
            class="mb-1"
            style="font-size: 14px"
          >
            <div v-html="$t('labels.change_order_item_warning_note')"></div>
          </v-alert>
          <v-alert
            color="purple"
            text
            dense
            border="left"
            class="mb-1"
            style="font-size: 14px"
          >
            <div v-html="$t('labels.change_order_item_success_note')"></div>
          </v-alert>
        </div>

        <div>
          <v-simple-table
            fixed-header
            height="calc(100vh - 500px)"
            class="table-padding-2"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" style="width: 80px"></th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 220px"
                  >
                    {{ $t("labels.barcode") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 80px"
                  >
                    {{ $t("labels.goods") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 80px"
                  >
                    {{ $t("labels.goods_description") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 80px"
                  >
                    {{ $t("labels.size") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 80px"
                  >
                    {{ $t("labels.available_quantity_1") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 100px"
                  >
                    {{ $t("labels.customer_order") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 100px"
                  ></th>
                </tr>
              </thead>
              <tbody
                v-for="(item, index) in items"
                :key="`di_${item.id}_${index}`"
              >
                <tr>
                  <td class="text-center">
                    <v-badge color="red"></v-badge>
                  </td>
                  <td
                    class="text-center"
                    :class="{
                      'text-decoration-line-through error--text':
                        deleteItems.includes(item.id),
                    }"
                  >
                    {{ item.customer_goods_barcode }}
                  </td>
                  <td
                    class="text-center"
                    :class="{
                      'text-decoration-line-through error--text':
                        deleteItems.includes(item.id),
                    }"
                  >
                    {{ item.name }}
                  </td>
                  <td
                    class="text-center"
                    :class="{
                      'text-decoration-line-through error--text':
                        deleteItems.includes(item.id),
                    }"
                  >
                    {{ item.description }}
                  </td>
                  <td
                    class="text-center"
                    :class="{
                      'text-decoration-line-through error--text':
                        deleteItems.includes(item.id),
                    }"
                  >
                    {{ item.size }}
                  </td>
                  <td
                    class="text-center"
                    :class="{
                      'text-decoration-line-through error--text':
                        deleteItems.includes(item.id),
                    }"
                  >
                    {{ item.available }}
                  </td>
                  <td
                    class="text-center"
                    :class="{
                      'text-decoration-line-through error--text':
                        deleteItems.includes(item.id),
                    }"
                  >
                    {{ item.quantity }}
                  </td>
                  <td class="text-center">
                    <template>
                      <v-btn
                        v-if="!deleteItems.includes(item.id)"
                        x-small
                        color="primary"
                        :disabled="isDisabledAddRow(item)"
                        @click="addRow(item, index)"
                      >
                        {{ $t("labels.change") }}
                      </v-btn>
                      <template
                        v-if="
                          !item.changeItems || item.changeItems.length === 0
                        "
                      >
                        <br />
                        <v-btn
                          v-if="!deleteItems.includes(item.id)"
                          class="mt-1"
                          x-small
                          color="error"
                          @click="deleteItem(item)"
                        >
                          {{ $t("labels.minus_order_item") }}
                        </v-btn>
                        <v-btn
                          v-else
                          class="mt-1"
                          x-small
                          color="warning"
                          @click="rollDeleteItem(item)"
                        >
                          {{ $t("labels.cancel_minus_order_item") }}
                        </v-btn>
                      </template>
                    </template>
                  </td>
                </tr>

                <tr
                  v-for="(cItem, cIndex) in item.changeItems"
                  :key="`ci_${item.id}_${cIndex}`"
                >
                  <td class="text-center">
                    <v-badge color="purple"></v-badge>
                  </td>
                  <td class="text-center">
                    <SelectSuggestGoodsLimit
                      :index="index"
                      :obj="{ item, cItem, cIndex }"
                      :default-value="cItem.customer_goods_barcode"
                      @onSelect="selectItem"
                    />
                    <!-- <v-text-field
                      v-model="cItem.customer_goods_barcode"
                      class="c-input-xs my-1"
                      type="text"
                      :label="$t('labels.barcode_and_enter')"
                      :placeholder="$t('labels.barcode_and_enter')"
                      dense
                      outlined
                      autofocus
                      hide-details
                      single-line
                      @keyup.enter="getItemDetail(item, index, cItem, cIndex)"
                    ></v-text-field> -->
                  </td>

                  <td>
                    <template v-if="cItem.type === 'combo'">
                      <div
                        v-for="(cbItem, cbIndex) in cItem.items"
                        :key="`n_${cbIndex}`"
                      >
                        {{ cbItem.name }}
                      </div>
                    </template>
                    <div v-else class="text-center">
                      {{ cItem.name || "" }}
                    </div>
                  </td>

                  <td>
                    <template v-if="cItem.type === 'combo'">
                      <div
                        v-for="(cbItem, cbIndex) in cItem.items"
                        :key="`d_${cbIndex}`"
                      >
                        {{ cbItem.description }}
                      </div>
                    </template>
                    <div v-else class="text-center">
                      {{ cItem.description || "" }}
                    </div>
                  </td>

                  <td>
                    <template v-if="cItem.type === 'combo'">
                      <div
                        v-for="(cbItem, cbIndex) in cItem.items"
                        :key="`s_${cbIndex}`"
                      >
                        {{ cbItem.size }}
                      </div>
                    </template>
                    <div v-else class="text-center">{{ cItem.size || "" }}</div>
                  </td>

                  <td class="text-center">
                    {{ cItem.available }}
                  </td>

                  <td class="text-center">
                    <div>
                      <v-text-field
                        v-model.number="cItem.quantity"
                        class="c-input-xs my-1"
                        :label="$t('labels.quantity')"
                        type="number"
                        min="1"
                        dense
                        outlined
                        hide-details
                        single-line
                        @blur="refreshItems"
                      ></v-text-field>
                    </div>
                    <!--<template v-if="cItem.type === 'combo'">
                      <div v-for="(cbItem, cbIndex) in cItem.items" :key="`a_${cbIndex}`">
                        {{ cbItem.quantity * cItem.quantity }}
                      </div>
                    </template>-->
                  </td>

                  <td class="text-center">
                    <v-btn
                      x-small
                      color="error"
                      @click="removeRow(item, index, cIndex)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabled"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ChangeReceiptedItem",
  components: {
    SelectSuggestGoodsLimit: () =>
      import("@/components/common/SelectSuggestGoodsLimit"),
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    deleteItems: [],
    name: null,
    idWarehouse: null,
    warehouses: [],
    newCod: null,
    timer: null,
    displayCountdown: null,
    expiredAt: null,
  }),
  computed: {
    isDisabled() {
      return !this.items || this.items.length === 0;
    },
    updateItems() {
      const deleteIds = [];
      let changeItems = [];
      const errorBarcodes = [];

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if (
          // item.quantity > item.available &&
          item.changeItems.length > 0 &&
          (item.changeItems[0].id_goods || item.changeItems[0].id_combo)
        ) {
          deleteIds.push(item.id);
          for (let j = 0; j < item.changeItems.length; j++) {
            const changeItem = item.changeItems[j];
            if (changeItem.type === "combo") {
              if (changeItem.id_combo) {
                const comboItems = changeItem.items;
                for (let cbi = 0; cbi < comboItems.length; cbi++) {
                  const comboItem = comboItems[cbi];
                  const comboItemQuantity =
                    comboItem.quantity * changeItem.quantity;
                  const checkItem = changeItems.find(
                    (ci) => ci.id_goods === comboItem.id_goods
                  );
                  if (!checkItem) {
                    changeItems.push({
                      id_org: item.id,
                      id_goods: comboItem.id_goods,
                      quantity: comboItemQuantity,
                    });
                  } else {
                    changeItems = [...changeItems].map((ci) => {
                      if (ci.id_goods === comboItem.id_goods) {
                        ci.quantity += comboItemQuantity;
                      }
                      return ci;
                    });
                  }
                }
              }
            } else {
              if (changeItem.id_goods) {
                const checkItem = changeItems.find(
                  (ci) => ci.id_goods === changeItem.id_goods
                );
                if (!checkItem) {
                  changeItems.push({
                    id_org: item.id,
                    id_goods: changeItem.id_goods,
                    quantity: changeItem.quantity,
                  });
                } else {
                  changeItems = [...changeItems].map((ci) => {
                    if (ci.id_goods === changeItem.id_goods) {
                      ci.quantity += changeItem.quantity;
                    }
                    return ci;
                  });
                }
              }
            }
          }
        }
      }

      if (this.deleteItems && this.deleteItems.length > 0) {
        deleteIds.push(...this.deleteItems);
      }

      return {
        deleteIds,
        changeItems,
        errorBarcodes,
      };
    },
  },
  created() {
    this.newCod = this.order.cash_on_delivery;
  },
  mounted() {
    this.getOrderItems();
    this.updatePreventStatus(true);
  },
  beforeDestroy() {
    this.updatePreventStatus(false);
    if (this.expiredAt) {
      clearInterval(this.timer);
    }
  },
  methods: {
    coundown() {
      const destinationTime = new Date(this.expiredAt);
      const currentTime = new Date();
      const timeDifference = destinationTime.getTime() - currentTime.getTime();

      if (timeDifference <= 0) {
        clearInterval(this.timer);
        this.displayCountdown = null;
        this.cancel();
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      if (days > 0) {
        this.displayCountdown = `${days} ngày ${hours} giờ`;
      } else if (hours > 0) {
        this.displayCountdown = `${hours} giờ ${minutes} phút`;
      } else if (minutes > 0) {
        this.displayCountdown = `${minutes} phút ${seconds} giây`;
      } else if (seconds > 0) {
        this.displayCountdown = `${seconds} giây`;
      } else {
        this.displayCountdown = `${hours} giờ ${minutes} phút ${seconds} giây`;
      }
    },
    updatePreventStatus(status) {
      httpClient
        .post("/orders-prevent-pickup", {
          status,
          id_order: this.order.id,
        })
        .then(({ data }) => {
          this.expiredAt = (data && data.expired_at) || 0;
          this.timer = setInterval(this.coundown, 1000);
        });
    },
    cancel() {
      this.$emit("cancel");
    },
    isDisabledAddRow(item) {
      if (item.changeItems.length === 0) {
        return false;
      }
      const checkItem = item.changeItems.filter(
        (ci) => !ci.id_goods && !ci.id_combo
      );
      return checkItem.length > 0;
    },
    async confirm() {
      if (this.isDisabled) {
        return false;
      }

      if (this.updateItems.errorBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.wrong_quantity_barcodes", {
            barcodes: this.updateItems.errorBarcodes.join(", "),
          })
        );
        return false;
      }
      /*const errorIndexs = []
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        if (!item.id && !errorIndexs.includes((i + 1))) {
          errorIndexs.push((i + 1))
        }
        const checkDuplicates = this.checkDuplicate(item, i)
        if (checkDuplicates && checkDuplicates.length > 0 && !errorIndexs.includes((i + 1))) {
          errorIndexs.push((i + 1))
        }
      }

      if (errorIndexs.length > 0) {
        this.$vToastify.warning(this.$t('messages.error_lines', {line:errorIndexs.join('; ') }));
        return false
      }*/

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/orders-change-item", {
          ...this.updateItems,
          cod: this.newCod,
          id_order: this.order.id,
          is_receipted: true,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    checkDuplicate(item, index, cItem, cIndex) {
      return [...item.changeItems].filter((jtem, jdex) => {
        return (
          jtem.customer_goods_barcode.toUpperCase() ===
            cItem.customer_goods_barcode.toUpperCase() && cIndex !== jdex
        );
      });
    },
    async getOrderItems() {
      try {
        const { data } = await httpClient.post("/orders-items-detail", {
          id: this.order.id,
        });
        this.items = [...data].map((d) => {
          d.changeItems = [];
          return d;
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    selectItem({ item, index, obj }) {
      const items = [...this.items];
      items[index].changeItems[obj.cIndex] = { ...item };
      this.items = [...items];
      setTimeout(() => {
        this.getItemDetail(obj.item, index, item, obj.cIndex);
      }, 200);
    },
    async getItemDetail(item, index, cItem, cIndex) {
      if (!cItem.customer_goods_barcode) {
        return false;
      }
      const duplicates = this.checkDuplicate(item, index, cItem, cIndex);
      if (duplicates.length > 0) {
        const duplicateCodes = [...duplicates].map(
          (i) => i.customer_goods_barcode
        );
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateCodes.join("; "),
          })
        );
        return false;
      }
      try {
        const { data } = await httpClient.post("/get-goods-for-change-item", {
          customer_goods_barcode: cItem.customer_goods_barcode,
          id_warehouse: this.order.id_warehouse,
        });
        if (data.combo) {
          const combo = { ...data.combo };
          const items = [...this.items];
          items[index].changeItems[cIndex] = {
            ...cItem,
            ...combo,
            type: "combo",
          };
          this.items = [...items];
        } else {
          const goods = { ...data.goods };
          const items = [...this.items];
          items[index].changeItems[cIndex] = {
            ...cItem,
            ...goods,
            type: "goods",
          };
          this.items = [...items];
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    refreshItems() {
      this.items = [...this.items];
    },
    addRow(item, index) {
      const items = [...this.items];
      items[index].changeItems.push({
        customer_goods_barcode: null,
        quantity: 1,
      });
      this.items = [...items];
    },
    deleteItem(item) {
      this.deleteItems.push(item.id);
    },
    rollDeleteItem(item) {
      this.deleteItems = [...this.deleteItems].filter((i) => i !== item.id);
    },
    removeRow(item, index, cIndex) {
      const items = [...this.items];
      items[index].changeItems = [...items[index].changeItems].filter(
        (jtem, jdex) => jdex !== cIndex
      );
      this.items = [...items];
    },
    getWarehouses() {
      const { warehouses } = window.me;
      this.warehouses = [...warehouses].map((w) => ({
        value: w.id,
        text: w.code_name || "",
      }));
      if (this.warehouses.length === 1) {
        this.idWarehouse = this.warehouses[0].value;
      }
    },
    totalQuantity(item) {
      const items = [...this.items].filter(
        (val) => val.id_goods == item.id_goods
      );
      let quantity = 0;
      for (let i = 0; i < items.length; i++) {
        const val = items[i];
        quantity += val.quantity;
      }
      return quantity;
    },
    totalQuantityChanged(cItem) {
      let quantity = 0;

      const items = [...this.items].filter(
        (val) =>
          val.id_goods == cItem.id_goods &&
          (!val.changeItems || val.changeItems.length == 0)
      );
      for (let i = 0; i < items.length; i++) {
        const val = items[i];
        quantity += val.quantity;
      }

      const changedItems = [...this.items].filter(
        (val) => val.changeItems && val.changeItems.length > 0
      );

      for (let i = 0; i < changedItems.length; i++) {
        const changedItem = changedItems[i];
        const child = [...changedItem.changeItems].find(
          (c) => c.id_goods == cItem.id_goods && c.id_combo == cItem.id_combo
        );
        quantity += (child && +child.quantity) || 0;
      }

      return quantity;
    },
  },
};
</script>

<style scoped></style>
